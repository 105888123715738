
  @import url('https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #336699; */
opacity: 0.8;

font-family: 'Roboto Condensed', sans-serif;

}

/* .App-header{
	display:flex;
	flex-direction:row;
	align-items: flex-start;
	justify-content: flex-start;

} */

.App-header {
	background-color: #746428;
	 display: flex;
	height: 7vh;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	color: white;
	/* background-image: linear-gradient(45deg, #fdd532 50%, #ec2 50%);
  background-size: 5px 5px; */
  }

  /*portal*/
  .portal{
padding:0vw 1vw 0vw 1vw;
display:flex;
flex-direction:column;
align-items: center;
justify-content: center;
width:100%;
height:100%;

  }
.portal .heading{
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 3.5vh;
	color:#fff;
	margin-top:1.0vh;
}
  .options{
	  display:flex;
	  flex-direction:row;
	  align-items: center;
	  justify-content: center;
	  width:100%;
	  margin-top:2vh;
	  margin-bottom:2vh;
  }

  .entry{
	  display:flex;
	  position:relative;
	  flex-direction:column;
	  align-items: center;
	  justify-content: flex-start;
	  flex:1;
	  margin-top:0vh;
	  margin-bottom:2vh;
	  margin-left:3vw;
	  margin-right:3vw;
	  cursor:pointer;
	  background-color:#fff;
	
	  
  }

.entry.desat{
	animation: 1s desaturate forwards;
}

  .entry .heading{
	  font-family: 'Roboto Condensed', sans-serif;
	  font-size: 2.5vh;
	  color:#303030;
	  margin-top:1.0vh;
  }
  .entry .img-wrapper{
	  width:100%;
	  
  }

  .entry .img-wrapper img{
	  width:100%;
	  height:auto;
	  border-radius:5px;
	  filter: saturate(0);
  }

  .entry.sfi{
  transform-origin: top left;
  }
  .entry.medprof{
  transform-origin: top center;
  }
  .entry.patient{
  transform-origin: top right;
  }

  .entry:hover{
  animation: 1s saturate forwards;
 z-index:10;
  }

@keyframes saturate{
	from{
		filter: saturate(0);
		transform:scale(1);
	}
	to{
		filter: saturate(1);
		transform:scale(1.3);
	}
}
@keyframes desaturate{
	from{
		filter: saturate(1);
		transform:scale(1.3);
	}
	to{
		filter: saturate(0);
		transform:scale(1.0);
	}
}

  .entry.sfi:hover{
	padding-right:25px;
	padding-bottom:25px;
	border-right: solid 1px #eee;
	border-bottom: solid 1px #eee;
  }

  .entry.patient:hover{
	padding-left:25px;
	padding-bottom:25px;
	border-left: solid 1px #eee;
	border-bottom: solid 1px #eee;
  }
  .entry.medprof:hover{
	padding-right:25px;
	padding-left:25px;
	padding-bottom:25px;
	border-right: solid 1px #eee;
	border-left: solid 1px #eee;
	border-bottom: solid 1px #eee;
  }

  .entry:hover .img-wrapper img{
	  filter: saturate(1);
  }

  /*******************/
	/*Professionals*/
	.upload-wrapper{
		display:flex;
		flex-direction:column;
		align-items: center;
		justify-content: center;
		width:100vw;
		height:100vh;
		background-image:url('images/exam_room.jpg');
		background-repeat: no-repeat;
		background-size:cover;
	}

	.bkgrd-filter{
		position:absolute;
		width:100%;
		height:100%;
		background-color: #fff;
		opacity:0.65;
		z-index:5;
	}

	.content-wrapper{
		display:flex;
		flex-direction:column;
		align-items: center;
		justify-content: flex-start;
		width:100%;
		height:100%;
		z-index:10;
		margin-top:4vh;
	}

	.label-upload{
		font-size:2vw;
	}
	.label-upload .MuiSvgIcon-root{
		font-size:9vw;
	}

	.manual-form{
margin-top:3vh;
	
	}
	.desc{
		margin-bottom:2vh;
	}

	.btn-show-form{
		position:absolute;
		bottom:3vh;
		left:6vw;
		margin-right:2vh;
		width:auto;
		box-sizing: border-box;
		
	}

	.link{
		text-align:center;
	}

	.btn-show-form button{
		
		margin-right:2vh;
	}

	.f-fields{
		display:flex;
		flex-direction:row;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		width:100%;
		margin-top:2vh;
		margin-bottom:2vh;
width:75vw;
	}

	.f-field{
		width:35vw;
		margin-right:2vw !important;
		margin-bottom:1.0vh !important;
		box-sizing: border-box;
		
	}

	.f-field .MuiInputBase-root{
		font-size: 1.35vh !important;
		padding: 0.75vw !important;
		background-color: rgba(255,255,255,0.9) !important;
	}

	.results{
		display:flex;
		flex-direction:column;
		align-items: flex-start;
		justify-content: center;
		width:60%;
		margin-left:20%;
		height:auto;
		font-size:1.2vw;
		margin-top:20px;
		width:auto;
		min-width:10vw;
	}
	.results .subheading{
		font-size:1.5vw;
		color:#336699;
		margin-top:2vh;
		margin-bottom:1vh;
	}
	.result{
		margin-bottom:.75vh;
	}

	.result span{
		font-weight:bold;
	}

  /*******************/

  .btn-quiz-wrapper{
	/* position:absolute;
	top:1vh;
	right:2vw; */
	flex:2;
	width:4vw;
	height:100%;display:flex;
	align-items:center;
	justify-content: flex-end;

  }

  .btn-quiz{
	margin-right:30px !important;
  }

  .App-link {
	color: #61dafb;
  }

.App-header .heading{
	font-family: 'Roboto Condensed', cursive;
	font-size: 3.5vh;
	color:#fff;
	margin-top:1.0vh;
}

.subtitle{

	font-size: 2vh;
	color:#336699;
	margin-top:-.5vh;
}

.hidden{
	display:none !important;
}

.patient-frame.faded-img {
	background-color: #fff;
}

.faded-img .patient-img{
	opacity:0.1;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#cgptResp{
	width:70%;
	font-size: .65em;
}

.dialog{
	font-size: .5em;;
}

.logo-wrapper{
	margin-left:1.0vw;
	margin-right:1.0vw;
	margin-top:0.0vh;
	/* background-color:#fff; */
	border-radius:50%;
	box-sizing: border-box;
	width:3.5vw;
	height:3.5vw;
	padding:0 .15vw;
	position:relative;
	z-index:100;
}

.titles{
	margin-right:3vw;
}

.logo{
	/* position:absolute; 
	top: 2vh;
	left:2vh;*/
	margin-top:5px;
	width: 100%;
	/* padding-left:.15vw;
	padding-top:.15vw; */
	/* max-width: 10px; */
}

.nav{
	display:flex;
	align-items: center;

	align-self: center;
	color:#303035;
	font-size:2.2vh;
	width:40%;
	height:90%;
}

.menu-item{
	display:flex;
	align-items: center;
	justify-content: center;
	flex:1;
	height:100%;
}
.menu-item:hover{
	background-color:#264f78;
	color:#fff;
	cursor:pointer
}
.menu-divider{
	flex:0.2;
}

.popup-box{
	/* display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center; */
	position:fixed;
	top:20%;
	left:10%;
	width:70%;
	height:auto;
	background-color: #fffff5;
	z-index: 999;
	padding:3vw;
	font-size:1.75vh;
}

.whatis-text div{
	margin-bottom:15px;
}
.close-btn{
	position:absolute;
	top:.25vw;
	right:2vw;
	width:2.25vw !important;
	 height:2.25vw !important;
	/* background-color: #336699; */
	color:#264f78;
	/* font-size:2.5vw; */
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
}

.close-btn .icon{
	font-size:1.75vw;
}

.qInput{
	background-color: #f5f5f5;
	border-radius: 7px;
}

.dialog-wrapper{
	display:flex;
	flex-direction:column;
	width:100%;
	min-height:93vh;
	margin-top:0vh;
	box-sizing: border-box;
	background-color: #ffffdd; 
	 /* background-image: url('images/study1.jpg'); 
	
	background-repeat: no-repeat;
	background-size:contain;  */
	 /* background-size: 750px 750px;  */
	/* background-image: linear-gradient(45deg, #264f78 50%, #336699 50%);
 */
}

.main-frame{
	display:flex;
	flex-direction:column;
	align-items:center;
	 justify-content:flex-start;
	box-sizing: border-box;
	height:90%;

	}
	.dialog-frame{
		/* height:100%; */
		display:inline-block;
		flex:4.25;
		width: 100%;
		height:100%;
		position:relative;
	}

	.patient-frame{
		display:flex;
		width:90%;
		align-items: flex-start;
		justify-content: center;
		margin-left:5%;
		position: relative;
		 height:100%;
		padding:0;
		margin-bottom:0px !important;
		border-left: solid 25px #fdd532;
		border-right: solid 25px #fdd532;
		border-bottom: solid 25px #fdd532;
		box-sizing: border-box;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		background-color:#fff ;
	}


	.patient-frame .img-container{

		object-fit: cover;
		flex:1 1 100%;
		height:100%;
		background-color:#fff ;
		/* border-bottom-left-radius: 20px; */
		overflow:hidden;
	}

	.patient-frame .patient-img{
		display:block;
		align-self: center;
		width:auto;
		height:100%;
		/* max-width: 100%; */
		max-height: 100%;
		box-sizing: border-box;
		/* border-bottom-left-radius: 20px; */
		/* border-bottom-right-radius: 20px; */
	}

	.patient-statements{
		flex:4 100%;
		bottom: 0;
		left:0;
display:flex;
flex-direction:column;
		min-height:100%;
		/* background-color: #ffffff85; */
		font-size: 1vw;
		color:#151515;
		overflow:hidden;
		max-width: 100%;
		padding:12px;
text-align:center;
box-sizing: border-box;
/* border-bottom-left-radius: 15px; */
		border-bottom-right-radius: 15px;

	}

	.intro-text{
		flex:1;
		font-size: 1.5vw;
		color:#151515;
		margin-top:2vh;
		margin-bottom:2vh;
	}

	.game-panel{
		position:relative;
		top:0px;
		left:0px;
		width:100%;
		height:100%;
		padding:1% 3% 1% 3%;
		box-sizing: border-box;
	}

	.stats{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:100%;
		margin-top:1.2vh;
	}

	.stats div{
		flex:1;
		font-size: 2vw;
		color:#336699;
	}

	.animal-panel{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:100%;
		margin-top:.5vh;
		font-size:7vh;
		color:#236523;
		font-family: 'Righteous', cursive;
	}

	.qa{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:100%;
		/* margin-top:2vh; */
		font-size:1.5vh;
		box-sizing: border-box;
	}

	.questions{
		flex:1;
		display:flex;
		flex-direction:column;
		align-items: flex-end;
		justify-content: center;
		padding-right:1.0vw;
	}

	.answers{
		flex:1;
		display:flex;
		flex-direction:column;
		align-items: flex-start;
		justify-content: center;
		padding-left:1.0vw;
		padding-top:3vh;

	}

	.question{
		background-color: #9af593;
		border-radius:10px;
		padding:8px;
		box-sizing: border-box;
		margin-bottom:.7vh;
	}

	.answer{
		background-color: #5ca8f5;
		border-radius:10px;
		padding:8px;
		box-sizing: border-box;
		margin-bottom:.7vh;
	}

	.game-over{
		display:flex;
		flex-direction:column;
		align-items: center;
		justify-content: center;
		width:100%;
		margin-top:2vh;
		font-size:2.5vh;
		color:#990000;

	}

	.dashboard{

		display:flex;
		flex:1;
		flex-direction:column;
		align-items:center;
		justify-content:top;
		background-color: #ffffdd;

	}

	.dashboard .heading{
		font-weight:bold;
		color:#336699;
		margin-bottom:5px;
	}


	.archive-wrapper{
		display:flex; 
		flex-direction:column;
		align-items:flex-start;
		justify-content:flex-start;
		width:100%;
		/* height:45vh; */
		background-color: #ffffdd;
		overflow-x:hidden;
		overflow-y:auto;
		margin-top:2vh;
		margin-right:3vw;
		padding:1.5vw;
		box-sizing: border-box;
		flex:4 4 30vh;
	}

	.archive{
		margin:.5vw;
		width:100%;
		text-align: left;
	}
	.archive-results .MuiInputBase-root {
		text-align: left;
		font-size: .8vw !important;
	width:100% !important;

	}
	.archive-results .MuiFormControl-root{
		text-align: left;
		font-size: .7 !important;
	width:90% !important;
	background-color: #ffffe8;

	}

	.archive-list{
		width:100%;

	}

	.talkframe-wrapper{
		display:flex;
		flex-direction: row;
		align-items:center;
		justify-content:center;
		width:100%;
		 margin-top:25px;
		margin-bottom:10px;
	}

	.talk-frame{
		flex:1;
		display:flex;
		flex-direction: row;
		flex-wrap:wrap;
		justify-content: center;
		align-items: center;
		/* margin-top:25px;
		margin-bottom:10px; */
	}

.param-panel{

	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: flex-start;
	width:100%;
	/* height:100%; */
	/* background-color: #ffffdd; */
	/* overflow-x:hidden; */
	/* overflow-y:auto; */
	
	padding:1.2vw;
	box-sizing: border-box;
	
}

.param-panel button{
	margin-right:15px;
}

	.q-input{
		flex:.25 15%;
		margin-right:25px !important;
		background-color: #f5f5f5;
		border-radius:5px;

	}

	.q-filter-input{
		flex:.25 8%;
		margin-left:12% !important;
		margin-right:25px !important;
		background-color: #f5f5f5;
		border-radius:5px;

	}

	.q-input label{
		background-color: #f5f5f5;
		padding:6px;
		border-radius:12px;
		font-size: .9vw;
		margin-top:-5px !important;
	}

	.q-input .MuiInputBase-root {
		font-size:.95vw !important;
		/* flex:3; */
	}


	.grid-wrapper{
		min-height:70vh;
	}

	.MuiDataGrid-cell{
		display:none !important;
	}
	.grid-q-cell{
		display:flex !important;
		flex-wrap: wrap;
		height:auto !important;
		/* max-height:600px !important; */
		min-height:auto !important;
		width:99% !important;
		max-width:99% !important;
		padding-left:2% !important;
		padding-right:1% !important;
		white-space: normal !important;
		line-height: normal !important;
		text-align:left;
		border-width:0px !important;
	}
	.grid-eval{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:98%;
		margin-top:20px;
		padding:10px;
		background-color: #f0f0d3;
		border-radius:5px;
	}

	.grid-eval div{
		flex:1;
		font-size: 0.65vw;
		font-weight: bold;
		color:#083251;
		margin-top:-3px;
		margin-left:2px;
	}

	.grid-answers{
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items: center;
justify-content: center;
	}
	.grid-question{
		padding-bottom:15px;
		
	}

.MuiAccordionSummary-root{
background-color: transparent !important;
border:none !important;

}
.MuiAccordian-root{
background-color: transparent !important;
border:none !important;
margin-bottom:15px !important;
}
.MuiPaper-root{
background-color: transparent !important;
border:none !important;
margin-bottom:15px !important;
}


	.grid-answer{
		width:45%;
		padding:7px;
		margin:5px;
		background-color: #e6d6a9;
		border-radius:5px;
	}

	.grid-answer.correct{
		
		background-color: #d8eab4;
	}

	.grid-explanation{
		margin-top:15px;
		margin-bottom:15px;
		width:80%;
		margin-left:10%;
		border: solid 1px #336699;
		border-radius:5px;
		padding:10px;
		background-color:#d8eab4
	}
	.hint{
		margin-top:15px;
		margin-bottom:15px;
		width:80%;
		margin-left:10%;
		border: solid 1px #336699;
		border-radius:5px;
		padding:10px;
		background-color:#d8eab4
	}

	.grid-details{
		margin-top:15px;
		width:100%;
	}

	.edit-btns{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: flex-start;
		width:100%;
		margin-top:1vh;
		margin-bottom:1vh;
	}

	.edit-btns button{

		margin-right:15px;
		margin-left:15px;
	}

	.grid-q.edit-mode{
		width:100%;
		
	}

.MuiAccordion-root{
	width:100%;
}
	
	.edit-answers .MuiTextField-root{

		width:60% !important;
		margin-left:15px;
		margin-bottom: 10px;
		}
	
		.MuiDataGrid-row{
			width:100% !important;
			
		}
	.edit-question .MuiTextField-root{

		width:90% !important;
		margin-left:15px;
		margin-bottom: 10px;
		}

	.edit-explanation .MuiTextField-root{

		width:90% !important;
		margin-left:15px;
		margin-bottom: 10px;
		}
	
		.edit-question .MuiInput-input{
	
		width:90% !important;
		font-size:1.4vh !important;
	margin-top:-5px;
		}
		.edit-explanation .MuiInput-input{
	
		width:90% !important;
		font-size:1.4vh !important;
	margin-top:-5px;
		}


	.edit-hint .MuiTextField-root{

	width:90% !important;
	margin-left:15px;
	margin-bottom: 10px;
	}

	.edit-hint .MuiInput-input{

	width:90% !important;
	font-size:1.4vh !important;
margin-top:-5px;
	}

	/* QUIZ*/

	.qNum{
		font-size: 2.5vh;
		color:#336699;
		margin-top:2vh;
		margin-bottom:2vh;
		position:absolute;
		top:0vw;
		right:2vw;
	}
.q-prog{
	width:75%;
	text-align: center;
	margin-left:12.5%;
	margin-top:10px;
}

.timer-num{
	font-size:.8vw;
	margin-top:-1.9vw;
}
	.qScore{
		font-size: 3.5vh;
		color:#336699;
		margin-top:2vh;
		margin-bottom:2vh;
		position:absolute;
		top:0vw;
		left:2vw;
	}

.final-score{
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	width:100%;
	margin-top:2vh;
	margin-bottom:2vh;
	}

	.final-score button{
		margin-right:2vh;
	}
	.final-score .score{
		margin:3vh;
		font-size: 3.5vh;
	}


	.final-score button{
		margin-right:2vh;
	}
	.final-score .score{
		margin:3vh;
		font-size: 3.5vh;
	}

.progress{
	position:absolute;
	top:2vw;
	left:2vw;
	width: 50px !important;
	height: 50px !important;
z-index:200;
}



	.quiz-heading{
		text-align:center;
		font-size:4.5vh;
		margin-top:10px;
		margin-bottom:10px;
	}
	.quiz-wrapper{
		position:absolute;
		top:0;
		left:0;
		width:100vw;
		height:100vh;
		background-color: #fff;
		z-index:200;
	}

	.settings-wrapper{
		position:absolute;
		top:1.5vh;
		left:0;
display:flex;
align-items: center;
justify-content: flex-start;
margin-bottom:25px;
	
	}

	.quiz .grid-explanation{
		display:none;
	}

	.quiz .hint{
		display:none;
	}
	.quiz .hint.show{
		display: flex;
    position: absolute;
	width:90vw;
	padding:6vw;
    z-index: 201;
    top: 20vh;
    left: 5vw;
    margin-left: 0;
    box-sizing: border-box;
	font-size:3.5vh;
	}
	
	.quiz .grid-explanation.show{
		display: flex;
    position: absolute;
	width:90vw;
	padding:6vw;
    z-index: 201;
    top: 20vh;
    left: 5vw;
    margin-left: 0;
    box-sizing: border-box;
	font-size:3.5vh;
	}

	.quiz .hint .close-icon{
		position:absolute;
		top:1vh;
		right:1vh;
		cursor:pointer;
		width:2vw;
		height:2vw;
		z-index:105;
	}

	.quiz .grid-explanation .close-icon{
		position:absolute;
		top:1vh;
		right:1vh;
		cursor:pointer;
		width:2vw;
		height:2vw;
		z-index:105;
	}

	.quiz-panel, .explainer-panel{
		display:flex;
		flex-direction:column;
		align-items: center;
		justify-content: center;
		width:85%;
		margin-left:7.5%;
		border:solid 1px #e5e5e5;
		border-radius:10px;
		padding:2vw;
		box-sizing: border-box;
		background-color:#fafafa;
	}

.quiz .grid-question{
		font-size:3.5vh;
		margin-bottom:2vh;
		padding-bottom:5px;
	
}


	.quiz .grid-answer{
		position:relative;
		cursor:pointer;
		min-height:10vh;
		display:flex;
		align-items: center;
		justify-content: center;
		font-size:3.25vh;
		padding:1.5vh;
		box-sizing: border-box; 
		text-align:center;
	}
	.quiz .grid-answer.correct{
		background-color: #EBDEBA;
	}

	.quiz .answered-incorrect{
		background-color: #ccc;
		opacity:.7;
		pointer-events: none;
	}

	.quiz .answered-correct{
		background-color: #81d051 !important;
		
		pointer-events: none;
	}

	.q-params-panel{

		position:absolute;
		height:100vh;
		width:100vw;
		z-index:50;
		background-color: #fff;
		padding-left:2vw;
		padding-right:2vw;
	}

.q-num{
	width:10vw;
}

.q-time{
	width:12vw;
}
	.params-container{

	}


	.src-select{
		flex:.2 1 10%;
		min-width:5vw;
		margin-right:2vh;
		background-color: #f5f5f5;
		
	}

	.MuiMenu-list{
		background-color: #f5f5f5;
	}

	.auto-topics{
		width:50vw !important;
		background-color: #f5f5f5 !important;
	}

	.auto-topics-editor{
		margin-left:1vw;
		margin-right:1vw;
		width:30vw !important;
		background-color: #f5f5f5 !important;
	}
	.MuiAutocomplete-popper{
		
		background-color: #f5f5f5 !important;
	
	}


	.explainer-panel{
		margin-top:2.5vh;
	}

	.explainer-panel button{
		margin-right:2.5vh;
	}

	.exp-inputs{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:95%;
		margin-top:2vh;
		margin-bottom:2vh;
	}
.q-header{
	text-align:center;
		font-size:4.5vh;
		margin-top:10px;
		margin-bottom:10px;
}

.sub-head{
	text-align:center;
		font-size:2.5vh;
		margin-top:10px;
		margin-bottom:5px;
}

.exp-question{
	text-align:center;
		font-size:3.5vh;
		margin-top:2px;
		margin-bottom:10px;
}

.inp-explain{
	width:90% !important;
	margin-bottom:15px !important;
}
.inp-explain .MuiInputBase-multiline{
	/* width:90vw !important; */
	min-height:15vh;
	background-color: #f5f5f5 !important;
	align-items: flex-start !important;
}

.exp-result{
	margin-bottom:2vh;
}

.exp-score{
	font-size:3.5vh;
	margin-bottom:1vh;

}

.exp-explain{
	font-size:2.5vh;
	margin-bottom:1vh;
}

	.quiz .a-num{
position:absolute;
top:.2vh;
left:1vh;
		font-size:1.75vh;
		color:#505050;

	}


	.quiz-btns-start{
		display:flex;
		flex-direction:row;
		align-items: center;
		justify-content: center;
		width:100%;
		margin-top:1vh;
		margin-bottom:1vh;
	}

	.quiz-btns-start button{
		margin-right:15px;
		margin-left:15px;
	}
	.quiz-btns{
		display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5.5vh;
    margin-bottom: 1vh;
	
	}

	.quiz-btns button{
		font-size: 2.5vh;
		margin-right:15px;
		margin-left:15px;
	}

	.mic{
		flex:.75;
		display:flex;
		justify-content: flex-start;
		padding-right:2vw;
	}
.mic-wrapper{
	position:relative;
	/* width:555px; */
	height:55px;
	width:55px;
	margin-right:15px;
}



.submit-btn{
	flex:1;
	padding-top:2vh;
	display:flex;
		justify-content: flex-start;


}

.submit-btn button{
	margin-right:15px;
	margin-left:15px;
}
.btn {
	border: none;
  padding: 0;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  font-size: 100%;
  color: #fff;
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
	position: relative;
	z-index: 999;
	display:flex;
	align-items:center;
	justify-content:center;
  cursor:pointer;
}

.btn .MuiSvgIcon-root{
	font-size:2.5em;
	color:#404040
}

.listening .btn {
	background-color:#bdf388;
}
.listening .btn .MuiSvgIcon-root{
	color:#990000;
}
.listening .pulse-ring {
	display:block;
}
.pulse-ring {
	display:none;

  content: '';
  width: 100%;
  height: 100%;
	background: #990000;
  border: 5px solid #990000;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  animation: pulsate infinite 1.5s;
}

.pulse-ring.delay{
	 animation-delay: 1s;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

.patient-stats{
	width:100%;
	margin-top:2vh;
}

.doctor-stats{
	width:100%;
	margin-top:2vh;
}

.stats-wrapper{
	display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	width:100%;
	/* height:100%; */
	justify-content: left;
	align-items:top;
}


.indicator{
	flex:1;
	padding-left:.5vw;
	padding-right:.5vw;
}

.indicator.dialog-score{

	padding-left:20%;
	padding-right:20%;
	margin-top:10px;
	margin-bottom:15px;
}
.MuiLinearProgress-bar{
	background-color: #52df36 !important;
}
.low-level .MuiLinearProgress-bar{
	background-color: #aa0000 !important;
}
.mid-level .MuiLinearProgress-bar{
	background-color: #f1bd12 !important;
}
.high-level .MuiLinearProgress-bar{
	background-color: #52df36 !important;
}

.ind-label{
	font-size: .8vw;
}
.ind-value{
	font-size: .9vw;
}

.metric{
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	font-size: .85vw;
	height:0.85vw;
	padding-left: .35vw;
	padding-right: .35vw;
	margin-right:.35vw;
	margin-bottom:15px;
}

.m-label{
	display:flex;
	justify-content: center;
	/* flex:1; */
	text-align:left;
	height:3vh;
}
.m-icon{
	/* position:relative; */
	display:flex;
	justify-content: top;
	/* flex:.25; */
	color:#336633;
	padding-left: .25vw;
	height:3vh;
}
.m-icon svg{
	display:flex;
	/* height: 1.35vw; */
	height: 85%;
}
.m-icon .m-value{
	display:flex;
	/* position:absolute;
	right:-.8vw;
	bottom:.2vw; */
	font-size:.65vw;
	color:#336633;
	font-weight:bold;
	width:.65vw;
}

.bkdrop-text{
	position:absolute;
	font-size: 1.1vw;
	color:#fff;
	text-align:center;
	margin-top:8vh;
	font-weight: normal;
}


.game-won-panel{
	position:relative;
	overflow:hidden;
	 display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width:100%;
	height:70%;
	padding-top:2vh;
}

.imgs-panel{
	flex:1 1 45%;
	height:80%;
	overflow:hidden
}

.descrip-panel{
	flex:1 1 45%;
	padding:0vw 2vw;
	text-align:left;
	line-height: 1.4;
	font-size:1.6vh;
	height:83%;
	overflow-y:auto !important;
}

.game-won-mssg{
	flex:2.5 2.5 100%;
	flex-direction:column;
	align-items: center;
	justify-content: center;
	width:100%;
	height:20%;
	font-size:2.0vh;
	color:#3f6c07;
	padding-top:2.5vh;

}

.slider-list{
	height:30vh;
}
.slider-frame{

height:100%;
}
.animal-img{
	display:flex;
	align-items: center;
	justify-content: center;
	/* position:relative; */
	width:100%;
	height:100%;


}

.animal-img img{
	/* flex:1; */
	/* position:relative; */
	display:block;
	max-width:100%;
	max-height:30vh;
	height:auto;
	width:auto;
	/* border-radius:10px !important; */
}

.btn-game-over{
	margin-top:2vh;

}

.btn-game-over button{
	margin-right: 1.5vw;

}

/* media query for mobile devices*/

 @media screen and (max-width: 1023px) {

	.m-icon svg{

		height: 65%;
	}
	.m-icon .m-value{

		font-size:1.5vh;

	}

	.popup-box{

		font-size:2.5vh;
	}

	.close-btn{
		 height:auto !important;
		}

 }